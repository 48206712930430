import { graphql, StaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import * as React from 'react'
import { Helmet } from 'react-helmet'

function Seo({ description, keywords, title, image, url }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription = description || data.site.siteMetadata.description
        const metaTitle = title || data.site.siteMetadata.title
        const metaImage = image || data.site.siteMetadata.image
        const metaUrl = url || data.site.siteMetadata.siteUrl
        const metaKeywords = keywords || ['Moolah', 'Xero']

        return (
          <Helmet
            htmlAttributes={{
              lang: 'en',
            }}
            title={metaTitle}
            meta={[
              {
                name: 'description',
                content: metaDescription,
              },
              {
                name: 'og:type',
                content: 'website',
              },
              {
                name: 'og:title',
                content: metaTitle,
              },
              {
                name: 'og:description',
                content: metaDescription,
              },
              {
                name: 'og:image',
                content: metaImage,
              },
              {
                name: 'og:url',
                content: metaUrl,
              },
              {
                name: 'twitter:title',
                content: metaTitle,
              },
              {
                name: 'twitter:description',
                content: metaDescription,
              },
              {
                name: 'twitter:image',
                content: metaImage,
              },
            ].concat(
              metaKeywords && metaKeywords.length > 0
                ? {
                    name: 'keywords',
                    content: metaKeywords.join(', '),
                  }
                : []
            )}
          />
        )
      }}
    />
  )
}

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        image
      }
    }
  }
`

Seo.propTypes = {
  description: PropTypes.string,
  keywords: PropTypes.array,
  title: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
}

export default Seo
