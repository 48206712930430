import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ContactForm from './ContactForm'
import Section from './Section'
import { Link } from 'gatsby'

const Footer = () => {
  return (
    <Section
      style={{
        width: '100vw',
        background: 'var(--dark)',
        color: 'white',
      }}>
      <Container>
        <Row>
          <Col md={12} lg={6}>
            <h5>
              <strong>About Us</strong>
            </h5>

            <p>
              We are a software development company with years of experience but also a
              business that uses Xero for our accounting. Our business is reliant upon
              recurring revenue and needed an easy way to report on repeating invoices
              within Xero. We thought “we can’t be the only ones with this problem”. This
              is why we decided to create moolah.
            </p>

            <StaticImage
              className='mt-3 mb-5 mb-lg-0'
              src='../images/logo-light.svg'
              alt='Moolah'
              height={40}
            />

            <div className='mt-lg-4 mb-4'>
              <StaticImage
                className='me-5'
                src='../images/xero-app.png'
                alt='Xero connected app'
                height={100}
              />

              <StaticImage
                src='../images/xero-developer.png'
                alt='Xero developer'
                height={100}
              />
            </div>
          </Col>

          {process.env.GATSBY_CONTACT_FORM_ENABLED && (
            <Col md={12} lg={{ span: 5, offset: 1 }}>
              <h5>
                <strong>Get in Touch</strong>
              </h5>
              <ContactForm />

              <div className='mt-5'>
                <Link to='/privacy-policy'>Privacy Policy</Link>
              </div>
            </Col>
          )}
        </Row>

        <Row className='mt-3'>
          <Col></Col>
        </Row>
      </Container>
    </Section>
  )
}

export default Footer
