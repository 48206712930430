import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import BsNavbar from 'react-bootstrap/Navbar'
import FreeTrialButton from './FreeTrialButton'
import * as styles from './Navbar.module.scss'

const Navbar = () => {
  return (
    <BsNavbar expand='lg' variant='dark' bg='dark' className={styles.navbar}>
      <Container>
        <BsNavbar.Brand as={Link} to='/' className='py-3'>
          <StaticImage src='../images/logo-light.svg' alt='Moolah' height={40} />
        </BsNavbar.Brand>
        <BsNavbar.Toggle />
        <BsNavbar.Collapse id='navbar'>
          <Nav className={`${styles.items} ms-auto`}>
          <Nav.Link as={Link} to='/features'>
              Features
            </Nav.Link>

            <Nav.Link as={Link} to='/help'>
              Help
            </Nav.Link>

            <Nav.Link as={Link} to='/contact'>
              Contact
            </Nav.Link>

            <Nav.Link as='a' href={process.env.GATSBY_APP_URL}>
              Login
            </Nav.Link>

            <div className='d-none d-lg-block'>
              <FreeTrialButton />
            </div>
          </Nav>
        </BsNavbar.Collapse>
      </Container>
    </BsNavbar>
  )
}

export default Navbar
