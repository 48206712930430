import * as React from 'react'
import { Button } from 'react-bootstrap'

const FreeTrialButton = ({ fontSize }) => {
  return (
    <Button
      as='a'
      href={process.env.GATSBY_APP_URL}
      variant='success'
      className='me-1 px-4'
      style={{ color: 'white', fontSize: fontSize ?? '1rem' }}>
      Start Free Trial
    </Button>
  )
}

export default FreeTrialButton
