import * as React from 'react'
import * as styles from './Section.module.scss'

const Section = ({ children, ...props }) => {
  return (
    <div className={styles.section} {...props}>
      {children}
    </div>
  )
}

export default Section
