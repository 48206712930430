import * as React from 'react'
import { Col, Row } from 'react-bootstrap'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const ContactForm = () => {
  const [submitting, setSubmitting] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)
  const [error, setError] = React.useState()
  const [values, setValues] = React.useState({
    name: '',
    email: '',
    message: '',
    main_field: '',
  })

  const handleChange = event => {
    setError('')
    const { name, value } = event.target
    setValues({ ...values, [name]: value })
  }

  const handleSubmit = async e => {
    e.preventDefault()

    if (!values.name || !values.email || !values.message) {
      setError('Please ensure you have filled in all fields.')
      return
    }

    setSubmitting(true)

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...values }),
    })
      .then(res => {
        if (res.status === 200) {
          setSubmitted(true)
        } else {
          setError('There was an error sending the message')
        }

        setSubmitting(false)
      })
      .catch(() => {
        setSubmitting(false)
        setError('There was an error sending the message')
      })
  }

  if (submitted) {
    return <p>Thanks for submitting your message!</p>
  }

  return (
    <Form
      onSubmit={handleSubmit}
      name='contact'
      data-netlify='true'
      netlify-honeypot='main_field'>
      <Row>
        <Col md={6}>
          <Form.Group className='mb-2'>
            <Form.Label>Your Name</Form.Label>
            <Form.Control
              name='name'
              size='sm'
              value={values.name}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>

        <Col md={6}>
          <Form.Group className='mb-2'>
            <Form.Label>Your Email</Form.Label>
            <Form.Control
              type='email'
              name='email'
              size='sm'
              value={values.email}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>

        <Form.Group className='d-none'>
          <Form.Label>Main Field</Form.Label>
          <Form.Control
            name='main_field'
            size='sm'
            value={values.main_field}
            onChange={handleChange}
          />
        </Form.Group>
      </Row>

      <Form.Group className='mb-4'>
        <Form.Label>How can we help?</Form.Label>
        <Form.Control
          as='textarea'
          name='message'
          size='sm'
          value={values.message}
          onChange={handleChange}
        />
      </Form.Group>

      <Button
        variant='primary'
        type='submit'
        style={{ color: 'white' }}
        disabled={submitting}>
        Send Message
      </Button>

      {error && (
        <Alert className='mt-4 p-2' variant='danger'>
          {error}
        </Alert>
      )}
    </Form>
  )
}

export default ContactForm
