import * as React from 'react'
import '../styles/global.scss'
import Footer from './Footer'
import Navbar from './Navbar'
import Seo from './Seo'
import PropTypes from 'prop-types'

const Layout = ({ footer, children }) => {
  return (
    <main>
      <Seo title='Moolah - Contact Us' />
      <Navbar />
      <div id='content'>{children}</div>
      {footer && (
        <footer id='footer'>
          <Footer />
        </footer>
      )}
    </main>
  )
}

Layout.propTypes = {
  footer: PropTypes.bool,
}

Layout.defaultProps = {
  footer: true,
}

export default Layout
